<template>
    <a-layout id="components-layout-demo-top-side">

        <a-layout-header class="header">
            <CompanyHeader></CompanyHeader>
        </a-layout-header>

        <a-layout-content style="">


    <div class="banner">
        <a href="#/newlogin">
        <img src="../static/xsnbanner.png" /></a>
    </div>
            <div class="bg">
                <div class="xiangmu">
                    <div class="xiangmudiv">
<div class="xiangmuimg">
    <div class="xiangmujieshao">
        <h3>项目介绍</h3>
    </div>
    </div>

                        
                        <p class="xiangmu_wz" style="margin-top: 51px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中医药文化是我国优秀传统文化的重要组成部分，正如习总书记所说：“中医药文化凝聚着深邃的哲学智慧和中华民族几千年的健康养生理念及其实践经验，是中国古代科学的瑰宝，也是打开中华文明宝库的钥匙。”继承和弘扬中医药文化对于推动“健康中国”建设、文化强国战略都发挥着不可替代的作用。</p>
                        <p class="xiangmu_wz">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中医药文化进校园不仅丰富学生的课余生活，提高了学生学习中医药文化的兴趣，弘扬了中医药文化，增强学生的文化自信，还能起到强身健体、提高疾病预防能力的作用。</p>
                        <p class="xiangmu_wz">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传承和发扬中国传统文化我们的职责和使命，希望能在孩子们的心中植下中医药传统文化的种子让它生根发芽，茁壮成长。</p>
                        <div class="xmvideo">
                            <video-player  style="width: 100%;height: 100%;object-fit:cover;"  class="video-player vjs-custom-skin"
                                           ref="videoPlayer"
                                           :playsinline="true"
                                           :options="playerOptions"
                            ></video-player>
                        </div>
                        <div class="xiangmuimg">
                            <div class="xiangmujieshao"  >
                                <h3>新闻中心</h3>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="lunbotu">
                    <vue-seamless-scroll data="newdata" :class-option="optionLeft" class="seamless-warp2">
                        <ul class="item">
                     <div class="xuexiaoc" v-for="(item,index) in newdata"  @click="detail(item.Id)">
                                <img :src="item.ListImage" />
                                <div class="xuexiaozz">

                                </div>
                                <h3>{{item.Title}}</h3>
                            </div>

                    <!--        <div class="xuexiaoc" @click="detail('e336bcfb-2ce3-0783-d042-92e22a6da6d0')">
                                <img src="../static/yuxin.jpg" />
                                <div class="xuexiaozz">

                                </div>
                                <h3>中医药文化走进育新小学</h3>
                            </div>
                            <div class="xuexiaoc" @click="detail('e617452d-72a4-1e4f-e61d-12372b90f6d5')">
                                <img src="../static/changpingshiyan.jpg" />
                                <div class="xuexiaozz">

                                </div>
                                <h3>中医药文化进驻昌平实验小学</h3>
                            </div>
                 -->



                        </ul>
                    </vue-seamless-scroll>
                    <div class="xiangmuimg">
                            <div class="xiangmujieshao"  >
                                <h3>试听课程</h3>
                            </div>
                            <div class="producevideo">
                            <video-player  style="width: 100%;height: 100%;object-fit:cover;"  class="video-player vjs-custom-skin"
                                           ref="videoPlayer"
                                           :playsinline="true"
                                           :options="videoOptions"
                            ></video-player>
                        </div>
                        </div>
                    <div class="tabbar">
                        <ul>
                            <li :class="activeindex==0?'xueqiliactive':'xueqili'"  @click="Tab(0)">第一学期</li>
                            <li :class="activeindex==1?'xueqiliactive':'xueqili'" @click="Tab(1)">第二学期</li>
                            <li :class="activeindex==2?'xueqiliactive':'xueqili'" @click="Tab(2)">第三学期</li>
                            <li :class="activeindex==3?'xueqiliactive':'xueqili'" @click="Tab(3)">第四学期</li>
                        </ul>
                    </div>

                    <div v-show="activeindex==0"  class="xueqidl">
                        <div class="xqkc">
                            <img  src="../static/dyxqkc.png" />

                        </div>

                    </div>
                    <div v-show="activeindex==1"  class="xueqidl">
                        <div class="xqkc">
                            <img  src="../static/drxqkc.png" />

                        </div>
                    </div>
                    <div v-show="activeindex==2"  class="xueqidl">
                        <div class="xqkc">
                            <img  src="../static/dsxqkc.png" />

                        </div>
                    </div>
                    <div v-show="activeindex==3"  class="xueqidl">
                        <div class="xqkc">
                            <img  src="../static/dsixqkc.png" />

                        </div>
                    </div>

                </div>
                <div class="xiangmuimg">
                    <div class="xiangmujieshao"  >
                        <h3>联系我们</h3>
                    </div>
                </div>
                <div class="companyprodu">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京实益拓展科技有限责任公司创建于1989年2月，注册资本1000万元，是中关村高新技术企业，并具广播电视局发的《广播电视节日制作经营许可证》，具备音、视频节目制作资质。</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三十余年来实益企业以“海阔天空地想、脚踏实地做、健康快乐地活”为企业文化，以“推出一代，储备一代，构思一代”为企业发展战略，以“看清楚、说明白、做到位”为工作原则，引领企业在激烈的市场大潮中艰苦创业，自强不息。2019年，为积极响应国家《健康中国2030规划纲要》的号召，实益企业果断转型至健康产业，斥资打造“健康知识普及培训系统”细化落实《健康中国行动》对健康知识普及行动的工作要求。</p>
               <p>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过三年的潜心打造，企业主营项目主要分为:面向校园的“中医药文化进校园”项目，以及面向工会职工的“全职康”项目。
               </p>
                </div>
            </div>

        </a-layout-content>

        <a-layout-footer style="padding: 0">
            <div class="copyrightdiv">
                <div class="copyright_content">
                    <span><a href="https://www.bjshiyi.cn/static/yyzz.png">北京实益拓展科技有限责任公司</a></span><span>|</span> <span><a target="_blank" href="http://beian.miit.gov.cn/">京ICP备14000240号-3</a></span>
            <span>|</span><span><a target="_blank" href="https://www.bjshiyi.cn/static/chubanwu.jpg">新出发京批字第直230186号</a></span>
                <span>|</span>
                <span>
            <a target="_blank" href="https://www.bjshiyi.cn/static/220669.jpg">新出发京零字第海220699号</a>
            </span>
            <span>|</span>            
           <span>北京市昌平区首开Long街-智能物联产业园N5栋-C</span><span>|</span><span>联系电话：400-923-1068</span>
                </div>
            </div>
        </a-layout-footer>
    </a-layout>


</template>

<script>

    import CompanyHeader from "./home/components/companyheader";
    import vueSeamlessScroll from 'vue-seamless-scroll'

    export default {
        name: "company",
        components: {
            vueSeamlessScroll,
            CompanyHeader
        },
        created(){
            //this.ukeyLoad();

          /*  this.getNews();*/
        },
        data(){
            return {
                newdata:[
                    {
                        Id:'a15b0b5d-ea94-e832-d2da-9b7900af5cde',
                        Title:'中医药文化进驻北京理工大学附属小学',
                        ListImage:'../static/ligongfushu.jpg'
                    },
                    {
                        Id:'e617452d-72a4-1e4f-e61d-12372b90f6d5',
                        Title:'中医药文化进驻昌平实验小学',
                        ListImage:'../static/changpingshiyan.jpg'
                    },
                    {
                        Id:'e336bcfb-2ce3-0783-d042-92e22a6da6d0',
                        Title:'中医药文化走进育新小学',
                        ListImage:'../static/yuxin.jpg'
                    },
                    {
                        Id:'ac3be199-8a48-11ec-bd6f-00163e0ce4c5',
                        Title:'中医药文化走进东升实验小学',
                        ListImage:'../static/dongshengshiyan.jpg'
                    },
                    {
                        Id:'af672d9a-8a48-11ec-bd6f-00163e0ce4c5',
                        Title:'中医药文化走进北京石油学院附属第二实验小学',
                        ListImage:'../static/shiyoufushu.jpg'
                    },
                    {
                        Id:'b2a84ea3-8a48-11ec-bd6f-00163e0ce4c5',
                        Title:'中医药文化走进永泰小学',
                        ListImage:'../static/yongtai.jpg'
                    }
                ],
                newsList:[

                ],
                data:[],
                activeindex:0,

                IsShrink:true,

                mimeCodec:'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',

                playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: true, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "https://videotest.bjshiyi.cn/sv/43f953b3-186729b9342/43f953b3-186729b9342.mp4" //url地址
                    }],
                    poster: "https://videotest.bjshiyi.cn/dddab18dd4944df8ba17bd17c579e799/snapshots/cc5f5403d0ca46df94b78117ec81add2-00005.jpg", //你的封面地址
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                },
                videoOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        //src: "https://videotest.bjshiyi.cn/sv/3bc9d27a-186dec83396/3bc9d27a-186dec83396.mp4" //url地址
                        src:"https://videotest.bjshiyi.cn/sv/15df7cbe-18a8bfc51bb/15df7cbe-18a8bfc51bb.mp4"
                    }],
                    //poster: "https://videotest.bjshiyi.cn/7fe646d0c23071edbfa425b7edcb0102/snapshots/c9d502bfbc3c4c0581a88436d8967763-00005.jpg", //你的封面地址
                    poster:"https://videotest.bjshiyi.cn/43bfcc2051ce71eeb987752281fd0102/snapshots/59628e7c4dcd4541bb4173f41eaeeea2-00002.jpg",
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                },
                swiperOption: {
                    direction: 'vertical',
                    height: 500,
                    slidesPerView: 'auto',
                    loop: true,
                    spaceBetween: 0,
                    speed: 4000,
                    freeMode: false,
                    freeModeMomentum: false,
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false
                    }
                },



            }
        },
        methods: {
            Tab(vals){
                this.activeindex=vals;
            },

            on_bot_enter () {

                this.mySwiper.stopAutoplay()
            },
            on_bot_leave () {
                console.log("leave")
                this.mySwiper.startAutoplay()
            },
            getNews(){
                let that=this;

                this.$axios.post("/api/news/list").then((res) => {
                    if (res.data.Message=="SUCCESS"){
                        that.newsList=[];



                        res.data.Data.forEach((item,index)=>{


                                that.newsList.push({
                                    ListImage:item.ListImage,
                                    Title:item.Title,
                                    Id:item.Id


                                });
                                console.log('that.newsList',that.newsList)

                            });

                            if(that.newsList==undefined)
                                that.newsList=[{}];
                        }


                });
            },
            detail(n){
                this.$router.push({ name:'companynews',query:{ num: n }})
            }
        },
        computed: {

            optionLeft () {
                return {

                    step: 0.6, // 数值越大速度滚动越快
                    limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 2, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)

                }
            }

        },

    }
</script>
<style>
    /* 适配各种屏幕尺寸 */
    @media (min-width: 1024px){
        body,html{font-size: 18px!important;}
    }
    @media (min-width: 1100px) {
        body,html{font-size: 20px!important;}
    }
    @media (min-width: 1280px) {
        body,html{font-size: 22px!important;}
    }
    @media (min-width: 1366px) {
        body,html{font-size: 24px!important;}
    }
    @media (min-width: 1440px) {
        body,html{font-size: 25px!important;}
    }
    @media (min-width: 1680px) {
        body,html{font-size: 28px!important;}
    }
    @media (min-width: 1920px) {
        body,html{font-size: 33px!important;}
    }

</style>
<style scoped lang="less">

    #components-layout-demo-top-side .header{
        height: 2.76rem;
        background-color: #fff;
        box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);


        box-sizing: border-box;
        padding: 0.63rem 1.78rem 0.6rem 1.7rem;
    }

    .banner{
        width: 100%;
        height: 467px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .bg{
        background-image: url("../static/xuexiaobg.png");

    }
    .xiangmu{
        width: 100%;

        background-repeat: no-repeat;
        .xiangmudiv{


            width: 1099px;
            margin: 0 auto;
            .xmvideo{
                margin-top: 62px;
                width:1099px;
               
            }


            .xiangmu_wz{
                width: 1055px;
                color: #121212;
                font-size: 24px;
            }
        }

    }
    .xiangmuimg{
        padding-top: 80px;
    
        .producevideo{
            margin: 0 auto;
                margin-top: 62px;
                width:1099px;
              
            }
        .xiangmujieshao{
            margin: 0 auto;
            width: 633px;
            height: 104px;

            background-image: url("../static/xiangmujieshao.png");
            h3{
                font-size: 50px;
                color: #fefefe;
                vertical-align: center;
                line-height: 90px;
                text-align: center;
            }
        }
    }
    .lunbotu{

            margin-top: 62px;
        background-repeat: no-repeat;
    }

    .seamless-warp2 {
        overflow: hidden;
        height: 380px;


        box-sizing: border-box;
        ul.item {

            overflow: hidden;
            white-space:nowrap; //处理块元素中的空白符和换行
            .xuexiaoc{
                cursor: pointer;
                height: 380px;
                width: 543px;
                float: left;
                margin-right: 57px;
                border: 4px solid #E8730F;

                position: relative;

                .xuexiaozz{

                    background-color: black;
                    opacity: 0.5;
                    height: 50px;
                    width: 100%;
                    bottom: 0;
                    position: absolute;

                }

                h3{
                    position: absolute;
                    bottom: 0;
                    font-size: 22px;
                    color: #ffffff;
                    width: 100%;
                    text-align: center;

                }
            }
            img {

                height: 100%;
                width: 100%;


            }
        }
    }
    .mytab{
        margin: 95px auto;
    }
    .tabbar{
        width: 1100px;
        height: 94px;

        margin-top: 75px;
        margin-left: auto;
        margin-right: auto;

        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                cursor: pointer;
                list-style:none; float:left; text-decoration:none; display:block;
                width:232px;padding:5px; margin-left: 31px; position:relative;

            }
        }

    }

    .xueqiliactive{

        background-color:#E8730F;
        color:#fefefe;
        width: 232px;
        height: 94px;
        border-radius: 15px 15px 0 0 ;
        text-align: center;
        vertical-align: center;
        font-size: 30px;
        vertical-align: center;
        text-align: center;
        line-height: 80px;
    }
   .xueqili{
       margin-top: 30px;
       background-color:#FFDEC1;
       color:#121212;
       width: 232px;
       height: 64px;

       border-radius: 15px 15px 0 0 ;
       font-size: 30px;
       line-height: 50px;
       text-align: center;
       vertical-align: center;

   }
   .xueqidl{
       background-color: #ffffff;
       width: 1100px;

       margin:0 auto;
        border-radius: 20px;
       z-index:1;
       .xqkc{

           position: relative;
           width: 100%;
           height: 100%;
           img{
               width: 100%;
               height: 100%;
           }

       }


   }
    .companyprodu{
        margin-top: 68px;
        margin-left: auto;
        margin-right: auto;

        padding-bottom: 64px;
        width: 1093px;

        p{
           width: 100%;
            color:#121212;
            font-size: 24px;
            margin-bottom: 30px;
        }
    }
.copyrightdiv{
    height: 133px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: center;
    .copyright_content{

        display: flex;
        flex-direction: row;
        padding-top: 56px;
        padding-bottom: 55px;

        span{
           white-space: nowrap;
            font-size: 16px;
            padding-right: 25px;
            font-weight: bold;
            color:#121212;
        }
    }
}
::v-deep .vjs-custom-skin > .video-js{
    width: 97%;
    height: 90%;
}




</style>
